<template>
  <div class="p-4">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xxxl-12">
        <div class="element-wrapper ">
          <div
            class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar  element-box"
          >
            <all-instances></all-instances>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import AllInstances from './AllInstances.vue';

export default {
  components: {
    AllInstances,
  },
};
</script>
