<template>
  <table
    id="tableSteps"
    class="table table-borderless"
    style="color: white;"
    v-if="list.length > 0"
  >
    <thead class="thead-dark">
      <tr>
        <th
          v-for="header in tableHeaders"
          :key="header"
          scope="col"
          style="text-align: center; background-color='black'"
        >
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody id="bodyOfTable">
      <tr v-for="item in list" :key="item.name">
        <td
          v-for="row in tableRows"
          :key="row"
          scope="col"
          style="text-align: center;"
          class="black-color list-cell"
        >
          <circle-progress
            v-if="item[row] > 0 && row === 'trainingProgress'"
            class="mx-auto text-dark"
            :percent="item[row]"
            :show-percent="true"
            :size="40"
            :border-width="4"
            :border-bg-width="4"
          ></circle-progress>

          <span v-else>
            {{ item[row] }}
          </span>
        </td>
        <td>
          <button
            class="btn btn-primary btn-sm"
            :disabled="['shutdown', 'available'].includes(item.instanceStatus)"
            @click="stopTraining(item)"
          >
            Stop Training
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div v-else>
    <p>No Training Details</p>
  </div>
</template>
<script>
import httpClient from '../../../../service/httpClient';
import dateHelper from '../../../shared/Helpers/dateHelper';
import CircleProgress from 'vue3-circle-progress';

export default {
  components: {
    CircleProgress,
  },
  data() {
    return {
      tableHeaders: [
        'Instance Name',
        'Instance Status',
        'Task Name',
        'Start Time',
        'Progress',
        'Phase',
        'Organization',
        '',
        '',
      ],
      tableRows: [
        'instanceName',
        'instanceStatus',
        'taskName',
        'startTrainingTime',
        'trainingProgress',
        'trainingPhase',
        'Organization',
      ],
      list: [],
    };
  },

  created() {
    this.getTrainingList();
  },
  methods: {
    async getTrainingList() {
      const res = await httpClient.get('organization/train/detail');
      this.list = res.map((el) => ({
        ...el,
        startTrainingTime: dateHelper.formatDate(el.startTrainingTime),
        trainingProgress: Number(el.trainingProgress).toFixed(1),
      }));
    },

    async stopTraining(data) {
      const { Organization, taskName } = data;

      const res = await httpClient.put(
        'organization/train/stop/',
        '',
        { organization: Organization, task_name: taskName },
        null,
        true,
        true,
        false
      );
      if (res === 'error') {
        console.log('error');
        return;
      }
      this.getTrainingList();
    },
  },
};
</script>
<style></style>
